<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetFormImposto"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Importar Imposto
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          ref="formImposto"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Tipo Imposto"
            rules="required"
          >
            <b-form-group
              label="Tipo Imposto"
              label-for="tipoImposto"
              class="mb-0"
            >
              <v-select
                v-model="imposto.tipoImposto"
                :options="impostosOptions"
                :reduce="val=>val.value"
              />
              <span class="undermessage-error text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Data de Vigência"
            rules="required"
          >
            <FormDatepicker
              id="dataVigencia"
              ref="refdate"
              v-model="imposto.dataVigencia"
              label="Data de Vigência"
              :required="true"
              @update="(novaData) => imposto.dataVigencia = novaData"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Arquivo de Importação"
            rules="required"
          >
            <b-form-group
              label="Arquivo de Importação"
              label-for="arquivoImportacao"
            >
              <b-form-file
                ref="fileInput"
                v-model="arquivoImportacao"
                placeholder="Escolha a planilha"
                drop-placeholder="Solte a planilha aqui"
                accept=".csv, .xls, .xlsx"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <a
                v-if="imposto.tipoImposto === 'ICMS'"
                href="https://portaljad.s3.sa-east-1.amazonaws.com/arquivos-impostos/Tabela ICMS 2023.xlsx"
                target="_blank"
                class="link-primary"
              >
                Layout modelo ICMS a ser seguido
              </a>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Importar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import formValidation from '@core/comp-functions/forms/form-validation'
import _ from 'lodash'
import { reactive, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import FormDatepicker from '@/components/form/FormDatepicker.vue'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'ImportarImpostoSidebar',
  components: { FormDatepicker },
  emits: ['aoSalvar'],
  props:
    {
      active: {
        type: Boolean,
        required:
          true,
      },
    },
  setup(props, { emit }) {
    const impostossStore = createNamespacedHelpers('impostos')

    const { save } = impostossStore.useActions(['save'])
    const blankImposto = {
      tipoImposto: null,
      dataVigencia: null,
      dataImportacao: null,
      nomeArquivoImportacao: null,
      urlArquivoImportacao: null,
      ativo: true,
    }

    const state = reactive({
      imposto: _.cloneDeep(blankImposto),
      impostosOptions: impostossStore.useGetters(['impostosOptions']).impostosOptions,
      arquivoImportacao: null,
    })

    const createMultipart = () => {
      const multipart = new FormData()
      multipart.append('file', state.arquivoImportacao)
      multipart.append('imposto', JSON.stringify(state.imposto))
      return multipart
    }

    const { appCarregando } = $themeConfig.app

    const onSubmit = () => {
      appCarregando.show()
      const multipart = createMultipart()
      save(multipart)
        .then(response => {
          appCarregando.hide()
          emit('update:active', false)
          emit('aoSalvar')
          if (response.success) {
            swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Imposto importado com sucesso!',
              showConfirmButton: true,
            })
          }
        }).catch(() => {
          appCarregando.hide()
          swal.fire({
            icon: 'info',
            html: '<h1>Erro ao importar</h1>',
            width: 450,
            showConfirmButton: true,
          })
        })
    }

    const resetImposto = () => {
      state.imposto = _.cloneDeep(blankImposto)
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetImposto)

    return {
      ...toRefs(state),
      // FORM CONFIGS
      refFormObserver,
      getValidationState,
      resetForm,
      // METHODS
      onSubmit,
      resetImposto,
    }
  },
  methods: {
    resetFormImposto() {
      this.$refs.formImposto.reset()
      this.$refs.refdate.clear()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>