<template>
  <b-card class="mb-0">
    <div
      v-if="loaded"
      :class="loaded ? 'loaded' : ''"
      class="loading-on"
    >
      <img
        src="../../../../assets/images/logo/icon.png"
        class="rotate-vert-center loading-logo-card"
        alt="Logo"
      >
    </div>
    <div v-else>
      <div class="titulo p-1">
        Serviços
      </div>
      <vue-apex-charts
        v-if="options.series.length > 0"
        type="donut"
        :options="options"
        :series="options.series"
        class="chart"
        height="350"
      />
      <div 
        v-else
        class="titulo p-1 text-center"
      >
        Não há dados
      </div>
    </div>

  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import utils from '@/helpers/utils'
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'GraficoRosca',
  components: { VueApexCharts },
  props: {
    series: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const loaded = computed(() => props.loading)

    const options = ref({
      series: [],
      labels: [],
    })

    watch(() => props.series, value => {
      options.value = {
        ...options.value,
        series: value,
        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, w }) => {
            const label = w.globals.labels[seriesIndex]
            const valor = series[seriesIndex]
            const percent = w.globals.seriesPercent[seriesIndex][0].toFixed(1)
            return `<b-badge class="p-1">${label}<br>${utils.maskCurrency(valor)} (${percent}%)</b-badge>`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: true,
                  formatter: val => `${utils.maskCurrency(Number(val))}`,
                },
              },
            },
          },
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
                formatter: val => `${utils.maskCurrency(Number(val))}`,
              },
            },
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          formatter: (val, opts) => {
            const legenda = `${opts.w.globals.labels[opts.seriesIndex]} ${utils.maskCurrency(opts.w.globals.initialSeries[opts.seriesIndex])} (${opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(1)}%)`
            return legenda
          },
        },
        dataLabels: {
          enabled: false,
        },
        chart: {
          type: 'donut',
        },
      }
    })

    watch(() => props.labels, value => {
      options.value = {
        ...options.value,
        labels: value,
      }
    })
    return {
      options,
      loaded,
      ...utils,
    }
  },
}
</script>

<style scoped lang="scss">
.titulo {
  font-weight: 500;
  font-size: 13px;
  color: #5E5873;
}

.card {
  min-height: 100%;
}

.loading-on {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-logo-card {
  height: 80px;
}

.loaded {
  height: 12em;
}
</style>