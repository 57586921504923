<template>

  <div>
    <cliente-add-sidebar
      :active.sync="clienteAddSidebarActive"
      @refresh="refresh"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <b-col
            cols="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>clientes por página</label>
          </b-col>
          <b-col
            cols="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="mostrarInativos"
            >
              MOSTRAR INATIVOS
            </b-form-checkbox>
          </b-col>
          <b-col 
            cols="3"
            class="d-flex justify-content-end"
          >
            <b-button
              v-if="permissoes.editar"
              variant="primary"
              @click="clienteAddSidebarActive = true"
            >
              <span class="text-nowrap">Adicionar Cliente</span>
            </b-button>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="tableRef"
        class="position-relative"
        :items="clientes"
        :fields="tableColumns"
        :filter="search.replace(/[./-]/g, '')"
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Busca sem resultados"
        primary-key="clienteId"
        :sort-by.sync="sortBy"
        show-empty
        responsive
        stacked="md"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #cell(telefone)="data">
          <span>{{ data.item.telefone ? maskPhone(data.item.telefone) : '' }}</span>
        </template>

        <template #cell(numeroDocumento)="data">
          <span>{{ data.item.tipoDocumento == 'CPF' ? maskCpf(data.item.numeroDocumento) : maskCnpj(data.item.numeroDocumento) }}</span>
        </template>

        <template #cell(validação)="data">
          <div class="validacao-tumbs">
            <div v-if="data.item.dadosValidosApi != null">
              <span
                :id="`tooltip-target-${data.index}`"
                :class="data.item.dadosValidosApi ? 'text-success' : 'text-danger'"
              >
                <feather-icon
                  :icon="data.item.dadosValidosApi ? 'ThumbsUpIcon' : 'ThumbsDownIcon'"
                  size="18"
                />
              </span>
              <b-tooltip 
                :target="`tooltip-target-${data.index}`"
                triggers="hover"
              >
                <span>
                  {{ !data.item.dadosValidosApi ? data.item.observacao : 'Dados envio válidos' }}
                </span>
              </b-tooltip>
            </div>
            <div v-if="data.item.dadosValidosReversaApi != null">
              <span
                :id="`tooltip-target-${data.index}-reversa`"
                :class="data.item.dadosValidosReversaApi ? 'text-success' : 'text-danger'"
              >
                <feather-icon
                  :icon="data.item.dadosValidosReversaApi ? 'ThumbsUpIcon' : 'ThumbsDownIcon'"
                  size="18"
                />
              </span>
              <b-tooltip 
                :target="`tooltip-target-${data.index}-reversa`"
                triggers="hover"
              >
                <span>
                  {{ !data.item.dadosValidosReversaApi ? data.item.observacaoReversa : 'Dados reversa válidos' }}
                </span>
              </b-tooltip>
            </div>
          </div>
        </template>
        <template #cell(acesso)="data">
          <template>
            <span
              :id="`tooltip-acesso-${data.index}`"
              :class="data.item.emailAcesso ? 'text-success' : 'text-muted'"
            >
              <feather-icon
                icon="LogOutIcon"
                size="18"
                cursor="pointer"
                @click="acessar(data.item)"
              />
            </span>
            <b-tooltip 
              :target="`tooltip-acesso-${data.index}`"
              triggers="hover"
            >
              <span>
                {{ 'Acesso Cliente' }}
              </span>
            </b-tooltip>
          </template>
        </template>
        <template
          v-if="permissoes.editar || permissoes.visualizar || permissoes.excluir" 
          #cell(ações)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item 
              v-if="permissoes.visualizar"
              :to="{ name: 'clientes-visualizar', params: {clienteId: data.item.clienteId} }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>

            <b-dropdown-item 
              v-if="permissoes.editar"
              :to="{ name: 'clientes-editar', params: { clienteId: data.item.clienteId } }"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template 
          v-else
          #cell(ações)=""
        >
          <span title="Nenhuma ação permitida">
            <feather-icon icon="SlashIcon"/>
          </span>
        </template>
      </b-table>
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import cleaveOptions from '@/libs/cleave/options'
import { mapGetters } from 'vuex'
import Utils from '@/helpers/utils'
import ClientesComposition from '../clientes.composition'
import ClienteAddSidebar from '../components/add-cliente-sidebar'

export default {
  name: 'CadastroClientes',
  components: { ClienteAddSidebar },
  computed: {
    ...mapGetters({
      firebaseUser: 'firebase/firebaseUser',
    }),
  },
  watch: {
    firebaseUser() {
      this.refresh()
    },
  },
  beforeMount() {
    this.$store.dispatch('clientes/findAll')
  },
  setup() {
    const currentUser = JSON.parse(localStorage.getItem('userName'))
    const permissoes = JSON.parse(localStorage.getItem('permissoes')).filter(p => p.permissaoId === 1)[0]

    const acessar = dataItem => {
      if (dataItem.emailAcesso) {
        const urlCliente = process.env.VUE_APP_ROOT_API_CLIENTE
        const url = encodeURI(`${urlCliente}auth/login/${dataItem.emailAcesso}/${dataItem.senhaAcesso.replaceAll('/', '-')}`)
        window.open(url, '_blank')
      } else {
        swal.fire({
          title: 'Atenção!',
          icon: 'warning',
          html: 'Defina um Usuário como ACESSO RÁPIDO',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          confirmButtonText: 'OK',
          buttonsStyling: false,
        })
      }
    }

    return { 
      ...ClientesComposition(),
      acessar,
      cleaveOptions,
      currentUser,
      permissoes,
      ...Utils,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.validacao-tumbs {
  display: flex;
  justify-content: space-evenly;
}
</style>

<style lang="scss">

</style>
