import { reactive, ref, toRefs, watch } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import formValidation from '@core/comp-functions/forms/form-validation'
import cleaveOptions from '@/libs/cleave/options'
import EtiquetaService from '@/service/EtiquetaService'

const clientesStore = createNamespacedHelpers('clientes')

export default () => {
  const { findById } = clientesStore.useActions(['findById'])
  
  const state = reactive({
    cliente: clientesStore.useGetters(['selected']).selected,
  })
  const resetCliente = () => {
    findById(state.cliente.clienteId)
  }
  const apagarLogo = () => {
    state.cliente.urlLogo = null
  }

  const { switchAtivo } = clientesStore.useActions(['switchAtivo'])
  const handleAtivo = async () => {
    await switchAtivo(state.cliente)
  }
  
  const cleaveKey = ref(0)

  function incrementCleaveKey() {
    cleaveKey.value += 1
  }

  watch(() => state.cliente, () => {
    incrementCleaveKey()
    watch(() => state.cliente.tipoDocumento, () => {
      incrementCleaveKey()
    })
  })

  const atualizaClienteServico = (data, servico, acao, value) => {
    let exbirAlerta = false
    let texto = '<ul>'
    if (value === false && acao === 'selecionar-servico' && state.cliente.servicoPadraoId === servico.servicoId) {
      exbirAlerta = true
      state.cliente.servicoPadraoId = null
      texto += `<li>Serviço ${servico.label} não é mais o serviço de e-mail padrão. Informe um serviço de e-mail padrão na aba "Conf. E-mail".</li>`
    }
    if (value === false && acao === 'selecionar-servico' && state.cliente.servicoPadraoIntegracaoId === servico.servicoId) {
      exbirAlerta = true
      state.cliente.servicoPadraoIntegracaoId = null
      texto += `<li style='margin-top: 15px;'>Serviço ${servico.label} não é mais o serviço de integração padrão. Informe um serviço de integração padrão na aba "Conf. Integrações".</li>`
    }
    if (value === false && acao === 'selecionar-servico' && state.cliente.servicoPadraoFtp === servico.servicoId) {
      exbirAlerta = true
      state.cliente.servicoPadraoFtp = null
      texto += `<li style='margin-top: 15px;'>Serviço ${servico.label} não é mais o serviço de FTP padrão. Informe um serviço de FTP padrão na aba "Conf. FTP".</li>`
    }
    texto += '</ul>'
    if (exbirAlerta === true) {
      swal.fire({
        icon: 'info',
        title: 'Atenção!',
        html: texto,
        showConfirmButton: true,
      })
    }
    const selecionados = data.filter(d => d.clienteServico.normal || d.clienteServico.reversa).map(s => s.clienteServico)
    state.cliente.clienteServicos = selecionados
  }

  const {
    refFormObserver,
    getValidationState,
    resetForm,
  } = formValidation(resetCliente)

  const enviaEtiquetaTeste = async () => {
    const cliente = {
      ...state.cliente,
    }
    const etiqueta = {
      clienteId: cliente.clienteId,
      servicoId: 3,
      nomeServico: 'PACKAGE',
      numeroNotaFiscal: '123',
      valorNotaFiscal: 123.99,
      reversa: false,
      remetente: {
        clienteId: cliente.clienteId,
        nome: cliente.nomeFantasia,
        tipoDocumento: cliente.tipoDocumento,
        numeroDocumento: cliente.numeroDocumento,
        email: cliente.email,
        telefone: cliente.telefone,
        cep: cliente.cep,
        uf: cliente.uf,
        cidade: cliente.cidade,
        bairro: cliente.bairro,
        logradouro: cliente.logradouro,
        numero: cliente.numero,
        complemento: cliente.complemento,
      },
      destinatario: {
        nome: 'João da Silva',
        tipoDocumento: 'CPF',
        numeroDocumento: '53479794025',
        email: 'email@remetente.com.br',
        celular: '11999999999',
        cep: '01310000',
        uf: 'SP',
        cidade: 'São Paulo',
        bairro: 'Centro',
        logradouro: 'Rua do Destinatário',
        numero: '123',
        complemento: 'teste',
      },
      volumes: [
        {
          peso: 0,
          pesoEmQuilo: 0,
          altura: 0,
          largura: 0,
          comprimento: 0,
          diametro: 0,
          formato: 'PACOTE',
          sequenciaVolume: 1,
        },
      ],
      conteudo: [],
    }
    return EtiquetaService.enviaEtiquetaTeste(etiqueta)
  }

  const enviaEtiquetaReversaTeste = async () => {
    const cliente = {
      ...state.cliente,
    }
    const etiqueta = {
      clienteId: cliente.clienteId,
      servicoId: 3,
      nomeServico: 'PACKAGE',
      numeroNotaFiscal: '123',
      valorNotaFiscal: 123.99,
      reversa: true,
      remetente: {
        nome: 'João da Silva',
        tipoDocumento: 'CPF',
        numeroDocumento: '53479794025',
        email: 'email@remetente.com.br',
        celular: '11999999999',
        cep: '01310000',
        uf: 'SP',
        cidade: 'São Paulo',
        bairro: 'Centro',
        logradouro: 'Rua do Destinatário',
        numero: '123',
        complemento: 'teste',
      },
      destinatario: {
        clienteId: cliente.clienteId,
        nome: cliente.nomeFantasia,
        tipoDocumento: cliente.tipoDocumento,
        numeroDocumento: cliente.numeroDocumento,
        email: cliente.email,
        telefone: cliente.telefone,
        cep: cliente.cep,
        uf: cliente.uf,
        cidade: cliente.cidade,
        bairro: cliente.bairro,
        logradouro: cliente.logradouro,
        numero: cliente.numero,
        complemento: cliente.complemento,
      },
      volumes: [
        {
          peso: 0,
          pesoEmQuilo: 0,
          altura: 0,
          largura: 0,
          comprimento: 0,
          diametro: 0,
          formato: 'PACOTE',
          sequenciaVolume: 1,
        },
      ],
      conteudo: [],
    }
    return EtiquetaService.enviaEtiquetaTeste(etiqueta)
  }

  return {
    ...toRefs(state),
    // FORM FUNCS
    refFormObserver,
    getValidationState,
    resetForm,
    // MISC
    cleaveKey,
    cleaveOptions,
    apagarLogo,
    handleAtivo,
    atualizaClienteServico,
    enviaEtiquetaTeste,
    enviaEtiquetaReversaTeste,
  }
}