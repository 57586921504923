<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    no-header
    right
    @hidden="resetFormTarifa"
    @change="(val) => $emit('update:active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Importar Tarifa
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{handleSubmit}"
        ref="refFormObserver"
      >
        <b-form
          ref="formTarifa"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Nome"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-for="nomeTarifa"
            >
              <b-form-input
                id="nomeTarifa"
                v-model="tarifa.nomeTarifa"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Especial 1"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Serviço"
            rules="required"
          >
            <b-form-group
              label="Serviço"
              label-for="servicoId"
            >
              <v-select
                v-model="tarifa.servicoId"
                :options="servicosOptions"
                :reduce="val=>val.value"
              />
              <span class="undermessage-error text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>

          <!-- ALTERAÇÃO SENDO CONFIRMADO, QUANDO CONFIRMADA DELETER -->
          <!-- <validation-provider
            #default="validationContext"
            name="Fator de Cobugem"
            rules="required"
          >
            <b-form-group
              label="Fator de Cobugem"
              label-for="tipoFrete"
            >
              <v-select
                v-model="tarifa.tipoFrete"
                :options="tipoFrete"
              />
              <span class="undermessage-error text-danger">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider> -->

          <validation-provider
            #default="validationContext"
            name="Data de Vigência"
            rules="required"
          >
            <FormDatepicker
              id="dataVigencia"
              ref="refdate"
              v-model="tarifa.dataVigencia"
              label="Data de Vigência"
              :get-validation-state="getValidationState"
              :required="true"
              @update="(novaData) => tarifa.dataVigencia = novaData"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Arquivo de Importação"
            rules="required"
          >
            <b-form-group
              label="Arquivo de Importação"
              label-for="arquivoImportacao"
            >
              <b-form-file
                ref="fileInput"
                v-model="arquivoImportacao"
                placeholder="Escolha a planilha"
                drop-placeholder="Solte a planilha aqui"
                accept=".csv, .xls, .xlsx"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <a
                href="https://portaljad.s3.sa-east-1.amazonaws.com/arquivo-tarifa/Modelo Tarifário Jadlog.xlsx"
                target="_blank"
                class="link-primary"
              >
                Layout modelo a ser seguido
              </a>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Observação"
          >
            <b-form-group
              label="Observação"
              label-for="observacao"
            >
              <b-textarea
                id="observacao"
                v-model="tarifa.observacao"
                placeholder="Observação sobre a tarifa"
              >
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-textarea>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Importar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import swal from 'sweetalert2/dist/sweetalert2.min'
import formValidation from '@core/comp-functions/forms/form-validation'
import _ from 'lodash'
import { reactive, ref, toRefs } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import FormDatepicker from '@/components/form/FormDatepicker.vue'
import { $themeConfig } from '@themeConfig'

export default {
  name: 'ImportarTarifaSidebar',
  components: { FormDatepicker },
  emits: ['aoSalvar'],
  props:
    {
      active: {
        type: Boolean,
        required:
          true,
      }
      ,
    },
  beforeMount() {
    this.$store.dispatch('servicos/findAll').then(() => {
      this.$store.dispatch('servicos/setOptions')
    })
  },
  setup(props, { emit }) {
    const servicosStore = createNamespacedHelpers('servicos')
    const tarifasStore = createNamespacedHelpers('tarifas')
    const servicosOptions = ref(servicosStore.useGetters(['servicosOptions']).servicosOptions)

    const { save } = tarifasStore.useActions(['save'])

    const blankTarifa = {
      nomeTarifa: null,
      servicoId: null,
      empresaId: null,
      dataVigencia: null,
      nomeArquivoImportado: null,
      observacao: null,
      dataImportacao: null,
      tipoFrete: null,
    }

    const state = reactive({
      tarifa: _.cloneDeep(blankTarifa),
      arquivoImportacao: null,
    })

    const createMultipart = () => {
      const multipart = new FormData()
      multipart.append('file', state.arquivoImportacao)
      multipart.append('tarifa', JSON.stringify(state.tarifa))
      return multipart
    }

    const { appCarregando } = $themeConfig.app

    const onSubmit = () => {
      appCarregando.show()
      const multipart = createMultipart()
      save(multipart)
        .then(response => {
          appCarregando.hide()
          emit('update:active', false)
          emit('aoSalvar')
          if (!response.success) {
            swal.fire({
              icon: 'info',
              html: `<h1>${response.body}</h1>`,
              width: 800,
              showConfirmButton: true,
            })
          }
        })
    }

    const resetTarifa = () => {
      state.tarifa = _.cloneDeep(blankTarifa)
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetTarifa)

    const tipoFrete = [
      'AEREO',
      'RODOVIARIO',
    ]

    return {
      ...toRefs(state),
      servicosOptions,
      // FORM CONFIGS
      refFormObserver,
      getValidationState,
      resetForm,
      tipoFrete,
      // METHODS
      onSubmit,
      resetTarifa,
    }
  },
  methods: {
    resetFormTarifa() {
      this.$refs.formTarifa.reset()
      this.$refs.refdate.clear()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>