import HttpClient from './http-clients/portaljad'

const baseUrl = 'empresa'

export default class EmpresaService {
  static async verificaOnline(empresaId) {
    try {
      if (!empresaId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, 'verifica-online', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async switchOnline(empresaId) {
    try {
      if (!empresaId) {
        return undefined
      }
      const { data } = await HttpClient.put([baseUrl, 'switch-online', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async salvarConfiguracoesUnidade(empresaId, config) {
    try {
      if (!empresaId) {
        return undefined
      }
      const { data } = await HttpClient.post([baseUrl, 'unidade', empresaId].join('/'), config)
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async configuracoesUnidade(empresaId) {
    try {
      if (!empresaId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, 'unidade', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }

  static async salvarEnvioDecConteudo(empresaId) {
    try {
      if (!empresaId) {
        return undefined
      }
      const { data } = await HttpClient.put([baseUrl, 'switch-conteudo', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
  
  static async getMsgErroFtp(empresaId) {
    try {
      if (!empresaId) {
        return undefined
      }
      const { data } = await HttpClient.get([baseUrl, 'unidade/msg-import', empresaId].join('/'))
      return data.body
    } catch (error) {
      throw error.response.data
    }
  }
}
