<template>
  <div>
    <b-card>
      <div 
        col="12"
        class="row"
      >
        <b-col
          cols="4"
        >
          <b-form-group
            label="Taxas Cotação de Frete API"
            label-for="dropdown"
            class="mb-0"
          >
            <b-dropdown
              id="dropdown" 
              class="mb-0"
              :class="dropdownStyle()"
              variant="outline-secondary"
            >
              <template #button-content>
                <span>Taxas Cotação de Frete API</span>
              </template>
              <b-dropdown-group
                v-if="taxasSeparadas.PESO"
                header="Peso"
              >
                <div class="ml-3 pr-5">
                  <b-form-checkbox-group
                    v-model="cliente.taxasCliente"
                    class="d-flex flex-wrap flex-column"
                  >
                    <b-form-checkbox
                      v-for="taxa in taxasSeparadas.PESO"
                      :key="taxa.id"
                      style="padding-bottom: 2px;"
                      :value="taxa.value"
                      :disabled="isDisabled(taxa)"
                    >
                      {{ taxa.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </b-dropdown-group>

              <b-dropdown-group
                v-if="taxasSeparadas.VOLUME"
                header="Volume"
              >
                <div class="ml-3">
                  <b-form-checkbox-group
                    v-model="cliente.taxasCliente"
                    class="d-flex flex-wrap flex-column"
                  >
                    <b-form-checkbox
                      v-for="taxa in taxasSeparadas.VOLUME"
                      :key="taxa.id"
                      style="padding-bottom: 2px;"
                      :value="taxa.value"
                      :disabled="isDisabled(taxa)"
                    >
                      {{ taxa.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </b-dropdown-group>
              <b-dropdown-group
                v-if="!taxasSeparadas.PESO && !taxasSeparadas.VOLUME"
                class="d-flex justify-content-center text-center"
              >
                <a @click="mudarParaTaxas()">
                  Taxas não cadastradas
                  <br>
                  clique para cadastrar
                </a>
              </b-dropdown-group>
            </b-dropdown>
          </b-form-group>

        </b-col>
        <b-col
          cols="4"
        >
          <validation-provider
            #default="validationContext"
            name="Fator Cubagem"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Fator Cubagem"
              label-for="fatorCubagem"
              class="mb-0"
            >
              <v-select
                id="fatorCubagem"
                v-model="cliente.fatorCubagem"
                :options="fatorCubagemOptions"
                :reduce="v=>v.value"
                placeholder="Fator Cubagem"
                :state="getValidationState(validationContext)"
              />
              <span class="undermessage-error">
                {{ validationContext.errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          cols="4"
        > 
          <validation-provider
            #default="validationContext"
            name="adicionalDiasEntrega"
            :rules="{ required: false }"
          >
            <b-form-group
              label="Dias a mais para  entrega"
              label-for="adicionalDiasEntrega"
            >
              <b-form-input
                id="adicionalDiasEntrega"
                v-model="cliente.adicionalDiasEntrega"
                :state="getValidationState(validationContext)"
                type="number"
                trim
                placeholder="Dias a mais para  entrega"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </div>
    </b-card>
    <b-card>
      <b-form-group>
        <b-table
          :items="tableData"
          :fields="tableColumns"
          stacked="sm"
        >
          <template #cell(normal)="data">
            <b-form-checkbox
              v-model="data.item.clienteServico.normal"
              @change="atualizaClienteServico(tableData, data.item, 'selecionar-servico', $event)"
            />
          </template>
          <template #cell(reversa)="data">
            <b-form-checkbox
              v-model="data.item.clienteServico.reversa"
              @change="atualizaClienteServico(tableData, data.item, 'selecionar-servico', $event)"
            />
          </template>
          <template #cell(tarifa)="data">
            <v-select
              class="select-tarifa"
              :value="tarifaSelected(data.item.servicoId, data.item.tarifaId)"
              :disabled="!(data.item.clienteServico.normal || data.item.clienteServico.reversa)"
              :reduce="value=>value.tarifaId"
              :options="tarifasOptions.filter(option => option.servicoId === data.item.servicoId)"
              @input="(value)=>selectTarifa(data.item, value)"
            />
          </template>
          <template #cell(taxaFixa)="data">
            <validation-provider
              #default="validationContext"
            >
              <CurrencyInput
                v-model="data.item.clienteServico.taxaFixa"
                :validation-context="validationContext"
                :show-symbol="true"
                :disabled="!(data.item.clienteServico.normal || data.item.clienteServico.reversa)"
                style="margin-bottom: 0"
                placeholder="Valor"
                @change="atualizaClienteServico(tableData, data.item, 'valor-taxa-fixa', $event)"
              />
            </validation-provider>
          </template>
        </b-table>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import store from '@/store'
import { reactive, toRefs, watch } from '@vue/composition-api'
import CurrencyInput from '@/components/form/CurrencyInput'
import router from '@/router'
import ClientesEditarComposition from '../clientes-editar.composition'

const servicosStore = createNamespacedHelpers('servicos')
const tarifasStore = createNamespacedHelpers('tarifas')
const taxasStore = createNamespacedHelpers('taxas')

export default {
  name: 'TabServicos',
  components: { CurrencyInput },
  beforeMount() {
    store.dispatch('servicos/findAll').then(() => {
      store.dispatch('servicos/setOptions')
    })
    store.dispatch('tarifas/findAll').then(() => {
      store.dispatch('tarifas/setOptions')
    })
    store.dispatch('taxas/findAll')
      .then(taxas => store.dispatch('taxas/setOptions', taxas))
  },
  setup() {
    const { cliente } = ClientesEditarComposition()

    const tarifaRelacionadaAoServico = servicoId => {
      const clienteServico = cliente.value && cliente.value.clienteServicos ? cliente.value.clienteServicos.find(s => s.servicoId === servicoId) : null
      return clienteServico ? clienteServico.tarifaId : null
    }

    const servicosState = reactive({
      servicosOptions: servicosStore.useGetters(['servicosOptions']).servicosOptions,
      tarifasOptions: tarifasStore.useGetters(['tarifasOptions']).tarifasOptions,
      tableData: [],
      itensSelecionados: [],
      taxas: taxasStore.useGetters(['taxasOptions']).taxasOptions,
      taxasSeparadas: {},
    })

    const fatorCubagemOptions = [
      { value: 'AEREO', label: 'AEREO - 6000' },
      { value: 'RODOVIARIO', label: 'RODOVIARIO - 3333' },
    ]

    const tarifaSelected = (servicoId, tarifaId) => {
      const tOption = servicosState.tarifasOptions.find(
        tarifaOption => tarifaOption.servicoId === servicoId && tarifaOption.tarifaId === tarifaId,
      )
      if (tOption) {
        return tOption.tarifaId
      }
      return null
    }

    const ajusteTaxasSeparadas = () => {
      const taxasSeparadas = {}
      servicosState.taxas.forEach(item => {
        if (!taxasSeparadas[item.tipoTaxa]) {
          taxasSeparadas[item.tipoTaxa] = []
        }
        taxasSeparadas[item.tipoTaxa].push(item)
      })
      servicosState.taxasSeparadas = taxasSeparadas
    }

    watch(() => servicosState.taxas, () => {
      ajusteTaxasSeparadas()
    })

    const selectTarifa = (tableItem, tarifaId) => {
      cliente.value.clienteServicos.forEach(clienteServico => {
        const clienteServicoToEdit = clienteServico
        if (clienteServicoToEdit.servicoId === tableItem.clienteServico.servicoId) {
          clienteServicoToEdit.tarifaId = tarifaId
        }
      })
      servicosState.tableData.forEach(item => {
        const itemToEdit = item
        if (itemToEdit.clienteServico.servicoId === tableItem.clienteServico.servicoId) {
          itemToEdit.tarifaId = tarifaId
          itemToEdit.clienteServico.tarifaId = tarifaId
        }
      })
    }

    const mudarParaTaxas = () => {
      router.push('/cadastro/taxas')
    }

    const getServicoSelected = id => {
      const servico = cliente.value.clienteServicos.find(s => s.servicoId === id)
      return servico
    }

    const dropdownStyle = () => {
      if (store.state.appConfig.layout.skin === 'dark') {
        return 'dropDark'
      }
      return 'drop'
    }

    watch(() => servicosState.servicosOptions, options => {
      options.forEach(option => {
        const tarifaId = tarifaRelacionadaAoServico(option.value)
        const servicoSelected = getServicoSelected(option.value)
        servicosState.tableData.push({
          clienteServico: { 
            normal: servicoSelected ? servicoSelected.normal : false,
            reversa: servicoSelected ? servicoSelected.reversa : false,
            servicoId: option.value,
            tarifaId,
            clienteId: cliente.value.clienteId,
            taxaFixa: servicoSelected ? servicoSelected.taxaFixa : null,
          },
          label: option.label,
          tarifaId,
          servicoId: option.value,
        })
      })
    })

    const isDisabled = taxa => {
      if (taxa && cliente.value.taxasCliente) {
        if (taxa.tipoTaxa === 'PESO') {
          return servicosState.taxasSeparadas.PESO.some(v => cliente.value.taxasCliente.includes(v.value) && v.value !== taxa.value) 
        } if (taxa.tipoTaxa === 'VOLUME') {
          return servicosState.taxasSeparadas.VOLUME.some(v => cliente.value.taxasCliente.includes(v.value) && v.value !== taxa.value)
        }
      }
      return false
    }

    const tableColumns = [
      { key: 'normal', sortable: false, label: 'Normal', thStyle: { width: '8rem' } },
      { key: 'reversa', sortable: false, label: 'Reversa', thStyle: { width: '8rem' } },
      { key: 'label', sortable: true, label: 'Nome do Serviço' },
      { key: 'tarifa', sortable: true },
      { key: 'taxaFixa', label: 'Taxa Fixa', sortable: true },
    ]

    return {
      ...toRefs(servicosState),
      ...ClientesEditarComposition(),
      // TABLE CONFIG
      tableColumns,
      fatorCubagemOptions,
      // METHODS
      isDisabled,
      mudarParaTaxas,
      dropdownStyle,
      selectTarifa,
      tarifaSelected,
    }
  },
}
</script>

<style lang="scss">
.checkbox-group div {
  margin-bottom: 20px;
}

.dropDark {
  width: 100%;
  button {
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    display: flex;
    justify-content: space-between;
    border-color: #404656 !important;
  }
  button:hover {
    background-color: transparent !important;
  }
}

.drop {
  width: 100%;
  button {
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    display: flex;
    justify-content: space-between;
    border-color: #d8d6de !important;
  }
  button:hover {
    background-color: transparent !important;
  }
  button::selection {
    background-color: transparent !important;
  }
}

.select-tarifa {
  width: 300px;
}
</style>