<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
      class="d-flex justify-content-center flex-column"
    >
      <div class="d-flex justify-content-start">
        <div class="ml-1">
          <h4>{{ data.nome }}</h4>
        </div>
      </div>
    </b-col>
    <b-col
      cols="12"
      md="8"
    >
      <table class="mt-2 mt-xl-0 w-100">
        <tr>
          <th
            class="pb-50"
            style="width: 30%"
          >
            <feather-icon
              icon="ArrowUpIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Arquivo Importado</span>
          </th>
          <td class="pb-50">
            <a
              :href="`${data.urlArquivoImportado}`"
              target="_blank"
            >
              {{ data.nomeArquivoImportado }}
            </a>
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Data de Importacao</span>
          </th>
          <td class="pb-50">
            {{ data.dataImportacao }}
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">Data de Vigência</span>
          </th>
          <td class="pb-50">
            {{ data.dataVigencia }}
          </td>
        </tr>
      </table>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'ModalVue',
  props: {
    data: {
      type: undefined,
      required: true,
    },
  },
}
</script>